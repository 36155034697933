import locale77427e78 from '../../../lang/en.js'
import locale77c06943 from '../../../lang/nl.js'
import locale77526775 from '../../../lang/fr.js'
import locale77304fa0 from '../../../lang/de.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"nl":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false,"timeZone":"Europe/Amsterdam"}},"en":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}},"fr":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}},"de":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","craftSiteHandle":"en","iso":"en","file":"en.js","group":"gladwell","domain":"https://www.gladwellacademy.com","formatted":"English","countryCode":"GB"},{"code":"nl","craftSiteHandle":"nl","iso":"nl","file":"nl.js","group":"gladwell","domain":"https://www.gladwellacademy.nl","formatted":"Nederlands","countryCode":"NL"},{"code":"fr","craftSiteHandle":"fr","iso":"fr","file":"fr.js","group":"gladwell","domain":"https://www.gladwellacademy.fr","formatted":"François","countryCode":"FR"},{"code":"de","craftSiteHandle":"de","iso":"de","file":"de.js","group":"gladwell","domain":"https://www.gladwellacademy.de","formatted":"Deutsch","countryCode":"DE"},{"code":"en-IN","craftSiteHandle":"in","iso":"en-IN","file":"en.js","group":"gladwell","domain":"https://www.gladwellacademy.in","formatted":"English","countryCode":"IN"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","craftSiteHandle":"en","iso":"en","file":"en.js","group":"gladwell","domain":"https://www.gladwellacademy.com","formatted":"English","countryCode":"GB"},{"code":"nl","craftSiteHandle":"nl","iso":"nl","file":"nl.js","group":"gladwell","domain":"https://www.gladwellacademy.nl","formatted":"Nederlands","countryCode":"NL"},{"code":"fr","craftSiteHandle":"fr","iso":"fr","file":"fr.js","group":"gladwell","domain":"https://www.gladwellacademy.fr","formatted":"François","countryCode":"FR"},{"code":"de","craftSiteHandle":"de","iso":"de","file":"de.js","group":"gladwell","domain":"https://www.gladwellacademy.de","formatted":"Deutsch","countryCode":"DE"},{"code":"en-IN","craftSiteHandle":"in","iso":"en-IN","file":"en.js","group":"gladwell","domain":"https://www.gladwellacademy.in","formatted":"English","countryCode":"IN"}],
  localeCodes: ["en","nl","fr","de","en-IN"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "/",
  21: "e",
  22: "n",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "n",
  30: "l",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: ".",
  41: ".",
  42: "/",
  43: "l",
  44: "a",
  45: "n",
  46: "g",
  47: "/",
  48: "n",
  49: "l",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "f",
  57: "r",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ":",
  63: "\"",
  64: ".",
  65: ".",
  66: "/",
  67: ".",
  68: ".",
  69: "/",
  70: "l",
  71: "a",
  72: "n",
  73: "g",
  74: "/",
  75: "f",
  76: "r",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: ",",
  82: "\"",
  83: "d",
  84: "e",
  85: ".",
  86: "j",
  87: "s",
  88: "\"",
  89: ":",
  90: "\"",
  91: ".",
  92: ".",
  93: "/",
  94: ".",
  95: ".",
  96: "/",
  97: "l",
  98: "a",
  99: "n",
  100: "g",
  101: "/",
  102: "d",
  103: "e",
  104: ".",
  105: "j",
  106: "s",
  107: "\"",
  108: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'nl.js': () => Promise.resolve(locale77c06943),
  'fr.js': () => Promise.resolve(locale77526775),
  'de.js': () => Promise.resolve(locale77304fa0),
}
